/* success.css */
.success-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #edf2f7; /* light blue background */
  }
  
  .success-card {
    text-align: center;
    width: 90%;
    max-width: 400px;
    padding: 2rem;
    border-radius: 0.5rem;
    background-color: #ffffff; /* white card */
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  
  .success-title {
    margin-bottom: 1rem;
    color: #1a202c; /* very dark gray */
    font-size: 1.5rem;
  }
  
  .success-message {
    margin-bottom: 2rem;
    color: #2d3748; /* dark gray */
  }
  
  .success-button {
    display: inline-block;
    color: white;
    background-color: rgb(0, 0, 255);
    border: none;
    border-radius: 0.25rem;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .success-button:hover {
    background-color: #5a67d8; /* darker light blue */
  }
  