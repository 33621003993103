.list-preview {
    background-color: var(--background);
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    margin: 10px;
    width: 300px;
    cursor: pointer;
  }
  
  .list-preview h3 {
    margin-bottom: 10px;
    color: var(--text-color);
  }
  
  .game-images {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .game-image {
    max-width: 100px;
    max-height: 100px;
    object-fit: cover;
    border-radius: 5px;
  }
  .game-image-container {
    max-width: 100px;
    max-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  