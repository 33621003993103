.review-bar {
    background-color: var(--background);
    padding: 1% 4%;  /* Reduced the vertical padding */
    margin: 1% 2%;
    border-radius: 20px;
    width: 95%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.review-bar:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.review-bar h1, .review-bar h2 {
    font-weight: 700;
    color: var(--text-color);
    margin-bottom: 16px;
    border-bottom: 2px solid #e0e0e0;
    padding-bottom: 8px;
    margin-bottom: 8px;  /* Reduced margin-bottom */
    padding-bottom: 4px;  /* Reduced padding-bottom */
}

.review-bar p, .review-bar span {
    color: var(--secondary-text-color);
    line-height: 1.5;
    margin-bottom: 8px;  /* Reduced margin-bottom */
}

.review-bar-button {
    background-color: var(--rating-color);
    color: var(--text-color);
    padding: 8px 16px; /* Increased horizontal padding */
    border-radius: 8px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    cursor: pointer;
    border: none;
    font-size: 1em;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Added a subtle shadow */
}

.review-bar-button:hover {
    background-color: var(--secondary-text-color);
    transform: translateY(-2px); /* Reduced translation for subtle lift */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Increased shadow on hover */
}

.review-stats-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem; /* Increased gap for better spacing between stats */
    justify-content: space-between;
    margin-top: 1rem; /* Added some top margin for better spacing */
    margin-top: 0.5rem;  /* Reduced top margin */
}

.rating-icon {
    width: 20px;
    height: auto;
    margin-left: 4px;
}

.review-header-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.25rem;
    margin-top: 1rem;
}

.review-title {
    font-size: 1.125rem; /* Equivalent to Tailwind's text-lg */
    font-weight: 700;
}

.review-stat {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centered items horizontally */
    text-align: center; /* Centered text */
}

.stat-title, .stat-value {
    margin-bottom: 0.125rem;  /* Reduced margin-bottom */
}

.rating {
    white-space: nowrap;  /* Ensure stars don't wrap */
    overflow: hidden;  /* Hide any overflowing content */
    text-align: center;
    margin-bottom: 16px; /* Added some space below the stars */
    margin-bottom: 8px;  /* Reduced margin-bottom */
}

.review-bar .fullStar, .review-bar .emptyStar, .review-bar .halfStar {
    font-size: 20px;
    margin: 0 1px;  /* Slight margin on each side */
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    vertical-align: middle;
}

.review-bar .fullStar {
    color: var(--rating-color);
}

.review-bar .halfStar {
    color: var(--star-color); 
}

.review-bar .halfStar::before {
    content: "★";
    position: absolute;
    top: 0;
    left: 0;
    color: var(--rating-color);
    clip-path: polygon(0 0, 50% 0, 50% 100%, 0% 100%);
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
}

.review-bar .emptyStar {
    color: var(--star-color); 
}

.toggle-container {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
}

.toggle-label {
    display: inline-block;
    position: relative;
    padding-left: 60px;
    cursor: pointer;
    font-size: 1rem;
    line-height: 1.5;
    color: var(--secondary-text-color);
}

.toggle-input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    padding-left: 10px;
}

.slider {
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    width: 60px;
    background-color: #ccc;
    border-radius: 34px;
    transition: 0.4s;
    margin-right: 10px;
}

.toggle-input:checked + .slider {
    background-color: var(--rating-color);
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    border-radius: 50%;
    transition: 0.4s;
}

.toggle-input:checked + .slider:before {
    transform: translateX(30px);
}
