/* RecentReviews.css */
.recent-reviews-wrapper {
  background-color: var(--wrapper);
}
.recent-reviews {
  padding: 20px;
  border-radius: 10px;
  margin: 20px 0;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.recent-reviews h2 {
  font-size: 1.8rem; /* Slightly larger font size */
  margin-bottom: 15px;
  color: var(--text-color);
}

.review-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px; /* Increased margin for better spacing */
}

/* Style for the sorting options */
.sort-options {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 15px;
}

.sort-options label {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  color: var(--secondary-text-color);
}

.sort-options input {
  margin-left: 5px;
}

/* Style for the selection boxes */
.sort-options input[type="checkbox"] {
  appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid var(--secondary-text-color);
  border-radius: 3px;
  cursor: pointer;
}

.sort-options input[type="checkbox"]:checked {
  border: 2px solid var(--rating-color);
}

.sort-options input[type="checkbox"]:checked::before {
  content: "✔";
  display: block;
  text-align: center;
  /* line-height: 18px; */
  font-size: 1rem;
  color: var(--rating-color);
}
