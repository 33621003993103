.about-grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr); /* Create a 12-column grid */
    gap: 6.5px; /* Adjust the gap between grid items as needed */
    border-radius: 25px;
    margin-bottom: 50px;
    color: var(--text-color);
}

.about-background {
    background-color: var(--wrapper);
}

.about-meettheteam {
    grid-column: 1 / span 12;
    font-size: 50px;
    text-align: center;
    margin-top: 80px;
}

.about-meettheteam-about {
    grid-column: 4 / span 6;
    text-align: center;
    font-size: 18px;
    margin-bottom: 40px;
}

.about-bio {
    grid-column: 2 / span 10;
    display: flex;
    margin-top: 40px;
}

.about-img {
    /* max-width: 200px; */
    width: 200px;
    border-radius: 20px;
}

.about-text {
    margin-left: 40px;
    margin-top: 20px;
    margin-right: 40px;
}

.about-name {
    font-size: 30px;
}