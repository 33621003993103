.FavoriteGames {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 450px;
    height: 150px;
    color: white;
    margin-right: 100px;
  }

  
  .EditGameCard {
    border: 1px solid white;
    width: 100;
    height: 125;
    text-align: center;
    cursor: pointer;
    border-radius: 20px;
  }
  
  .EditGameCard .SelectedGame{
    border: 1px solid white;
    width: 100;
    height: 125;
    text-align: center;
    cursor: pointer;
    border-radius: 20px;
  }
  .selected {
    border-width: 10;
    border: 2px solid red;
  }
  
  .ModalContent {
    border: 2px solid white;
    height: 500px;
    width: 500px;
    background-color: grey;
    align-items: center;
  }
  .searchedGamesBox {
    /* Styles for the container of searched games */
    display: flex;
    flex-direction: row;
    width: 500px;
    height: 150px;
    border: 1px solid white;
    padding: 10px;
    gap: 10px;
    justify-content: center;
  }

  .Popup  {
        border: "2px solid white";
        height: 500;
        width: 500;
        background-color: "grey",
  }
  