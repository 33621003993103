.game-cardd {
    background-color: var(--background);
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.game-cardd:hover {
    transform: translateY(-5px); 
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); 
}

.title-play-buttons-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 16px;
    margin-bottom: 16px;
}

.add-to-list {
    text-transform: none;
    width: 100%;
}
