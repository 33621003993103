.custom-avatar {
    border-radius: 50%;
    object-fit: cover;
}
.medium-avatar {
    width: 200px;
    height: 200px;
}
.profile-card{
    height: 250px;
}
.userContainer{
    max-height: 250px;
}
.profile-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }