.comment-text-field-container {
    max-width: 100%;
    padding: 1rem;
    background-color: var(--background);
    border-radius: 20px;
}

.comment-text-field {
    /* width: 100%; */
    height: 15rem;
    padding: 0.5rem;
    border-radius: 20px;
    background-color: var(--background);
    color: var(--text-color);
    resize: none;
    outline: none;
    transition: border-color 0.3s ease;
}

.comment-text-field::placeholder {
    color: var(--secondary-text-color);
}

.comment-text-field:focus {
    border-color: var(--rating-color);
}

.comment-character-count {
    margin-top: 3rem;
    text-align: right;
    color: var(--secondary-text-color);
}

.error-text {
    color: red;
}

