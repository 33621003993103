.trending-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Create a 12-column grid */
    gap: 6.5px; /* Adjust the gap between grid items as needed */
    margin-bottom: 50px;
}

.trending-featured1 {
    grid-column: 1 / span 2;
}

.trending-head {
    font-size: 20px;
    font-weight: bold;
    color: var(--text-color);
}