/* CommentDisplay.css */

.comment-display {
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* width: 100%; */
}

.comment-box {
    background-color: var(--background);
    padding: 2% 4%;
    /* margin: 1% 2%; */
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    width: 100%;
    box-sizing: border-box;
}

.comment-box:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.comment-header {
    background-color: rgba(var(--rating-color), 0.1);
    padding: 10px 15px;
    border-bottom: 3px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease;
    margin-bottom: 20px;
}

.user-info-container {
    display: flex;
    flex-direction: row; /* Change to row direction */
    align-items: center;
    text-decoration: none;
    flex-grow: 1; /* Allow it to take up available space */
}

.custom-avatarr {
    border-radius: 50%;
    object-fit: cover;
    width: 50px;
    height: 50px;
    margin-bottom: 0.5rem;
    margin-left: 2rem;
}

.user-info {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.user-info .comment-username {
    font-weight: 600;
    color: var(--text-color);
    text-align: center;
}

.user-info .comment-time {
    font-size: 12px;
    color: var(--secondary-text-color);
    text-align: center;
}

.comment-text {
    color: var(--secondary-text-color);
    line-height: 1.5;
    margin-bottom: 16px;
    margin-top: 20px;
}

.like-button-container {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.comment-and-reply-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.reply-box {
    width: 80%;
    margin-left: 20%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.reply-display {
    display: flex;
    flex-direction: column;
    gap: 20px;
}


.delete-reply-icon {
    color: red; /* Makes the icon red */
    width: 20px; /* Adjust the size as needed */
    height: 20px; /* Adjust the size as needed */
    cursor: pointer; /* Changes cursor to pointer when hovering over the icon */
    margin-left: auto; /* Aligns the icon to the right within its container */
}

.dialog-title {
    font-size: 1.25rem; /* Tailwind text-lg */
    font-weight: 700; /* Tailwind font-bold */
    text-align: center; /* Center the title */
    padding: 1rem; /* Tailwind p-4 */
}

.dialog-content {
    padding: 1rem; /* Tailwind p-4 */
}

.dialog-actions {
    justify-content: center; /* Center the buttons */
    padding: 1rem; /* Tailwind p-4 */
}

.dialog-button {
    background-color: #4f46e5; /* Tailwind bg-indigo-600 */
    color: white; /* Tailwind text-white */
    padding: 0.5rem 1rem; /* Tailwind p-2 px-4 */
    margin: 0 0.5rem; /* Tailwind mx-2 */
    border-radius: 0.25rem; /* Tailwind rounded-md */
    font-weight: 500; /* Tailwind font-medium */
    cursor: pointer; /* Tailwind cursor-pointer */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.dialog-button:hover {
    background-color: #4338ca; /* Tailwind bg-indigo-700 for hover effect */
}

.delete-comment-icon {
    color: red; /* Adjust as needed */
    width: 20px; /* Adjust size as needed */
    height: 20px; /* Adjust size as needed */
    margin-left: 12px; /* Space from the like counter */
    cursor: pointer;
}

.toggle-replies-icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
    color: var(--secondary-text-color); /* Adjust the color as needed */
    margin-right: 10px;
}

/* Custom styles for Material-UI Select component */
.comment-filter-select {
    background-color: var(--background) !important; /* Your background color */
    color: var(--text-color) !important; /* Your text color */
    border-color: var(--border-color) !important; /* If you have a border color */
    border-radius: 8px !important; /* Adjust border radius as needed */
}


.comment-filter-select .MuiOutlinedInput-notchedOutline {
    /* border-color: var(--rating-color) !important; */
}


.comment-filter-select .MuiSelect-select {
    padding: 10px;
}

.comment-filter-menu {
    background-color: var(--background) !important; /* Your background color */
    outline-color: black !important; /* Your outline color */
}


.comment-filter-menuitem:not(:last-child) {
    border-bottom: 1px solid var(--star-color); /* Replace with your border color */
}


/* Custom styles for Material-UI MenuItem component */
.comment-filter-menuitem {
    color: var(--text-color) !important;
    background-color: var(--background) !important;
}

/* Custom styles for Material-UI InputLabel component */
.comment-filter-label {
    color: var(--text-color) !important; /* Your text color */
}

/* Styles for the hover effect */
.comment-filter-menuitem:hover {
    background-color: var(--rating-color) !important;
}




