.grid-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr); /* Create a 12-column grid */
    gap: 13px; /* Adjust the gap between grid items as needed */
    margin-top: 25px;
    
}
  
.grid-pad {
    padding: 10px;
}
  
.grid-featured {
    grid-column: 2 / span 6; /* Set the grid item to start at column 2 and span 6 columns */
}

.grid-activity {
    grid-column: 8 / span 4;
}

.grid-recommend {
    grid-column: 2 / span 10;
}

.grid-upcoming {
    grid-column: 2 / span 10;
}

.shitty-background-fix {
    background-color: var(--wrapper);
}