.recommend-container {

}

.recommend-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Create a 12-column grid */
    gap: 6.5px; /* Adjust the gap between grid items as needed */
    border-radius: 25px;
    margin-bottom: 50px;
}

.recommend-head {
    font-size: 20px;
    font-weight: bold;
}