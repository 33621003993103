body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
}

.image-cont {
    position: relative;
    overflow: hidden;
}

.Featured2-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.text-overlay {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.0);
    color: #fff;
    padding: 0.5rem;
    z-index: 1;
}

.game-name2 {
    font-size: 1.2rem;
    font-weight: bold;
}

.developer {
    font-size: 0.9rem;
    margin-right: 3px;
}


.image-container2::before {
    content: ''; /* Required for the pseudo-element to be displayed */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, transparent 50%, rgba(0, 0, 0, 1) 100%); /* Gradient from transparent to black */
    z-index: 1; /* Place the overlay above the image */
    pointer-events: none; /* Allow interactions with the underlying image */
}

/* Style the image */
.image-container2 img {
    display: block;
    width: 100%;
    height: auto;
    z-index: 2; /* Place the image above the overlay */
    /* max-height: 300px; */
}


.rounded-corners {
    border-radius: 25px; /* You can adjust the value to control the roundness */
    background-color: red; /* Optional background color */
    /* Other CSS properties for styling */
}

.limited-height {
    max-height: 165px;
}
