.user-recommend-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Create a 12-column grid */
    gap: 6.5px; /* Adjust the gap between grid items as needed */
    background-color: var(--background);
    color: var(--text-color);
    border-radius: 25px;
    padding: 12px;
}

.recommend-cover {
}

.recommend-cover-rounded {
    border-radius: 15px;
}

.right-mar {
    margin-right: 0px;
}

.recommend-genre {
    display: grid;
    text-align: center;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
}