/* ReviewCard.css */
.review-card {
  background: var(--background);
  border: 2px solid;
  border-radius: 10px;
  margin: 10px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
}

.rating {
  font-size: 1.2rem;
  font-weight: bold;
}

.game-info {
  display: flex; /* Display game name and cover side by side */
  align-items: center; /* Vertically align the content */
}

.game-name {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--text-color);
  margin-bottom: 10px;
  flex-grow: 1; /* Allow game name to grow and fill available space */
}

.review-card-game-cover {
  max-width: 100px; /* Set a fixed width for the game cover image */
  height: auto;
  display: block;
  margin-top: 10px;
}

.timestamp {
  color: var(--secondary-text-color);
  font-size: 0.9rem;
  margin-top: 10px;
}

.review-text {
  font-size: 1rem;
  margin-top: 10px;
  color: var(--secondary-text-color);
}

/* You can customize the color scheme further based on your project's design. */
