@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    box-sizing: border-box;
  }
  
  /* Scrollbar */
  ::-webkit-scrollbar {
    width: 0.5vw;
  }
  
  ::-webkit-scrollbar-track {
    background-color: var(--wrapper);
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: var(--secondary-text-color);
    border-radius: 10px;
  }

/* Define only the values for the light theme */
:root {
    --background-light: #e5e7eb;
    --text-color-light: #333;
    --secondary-text-color-light: #555;
    --rating-color-light: #618edb;
    --star-color-light: #bbbac0;
    --wrapper-light: #f0f2f5;
}

:root {
    --background-dark: #4b5563;
    --text-color-dark: #f5f5f5;
    --secondary-text-color-dark: #CAC7C7;
    --rating-color-dark: #92a8d1;
    --star-color-dark: #bbbac0;
    --wrapper-dark: rgb(107, 114, 128);
}

/* Define default variables for light theme */
:root {
    --background: var(--background-light);
    --text-color: var(--text-color-light);
    --secondary-text-color: var(--secondary-text-color-light);
    --rating-color: var(--rating-color-light);
    --star-color: var(--star-color-light);
    --wrapper: var(--wrapper-light);
}

/* If the user prefers dark mode, override the variables */
@media (prefers-color-scheme: dark) {
    :root {
        --background: var(--background-dark);
        --text-color: var(--text-color-dark);
        --secondary-text-color: var(--secondary-text-color-dark);
        --rating-color: var(--rating-color-dark);
        --star-color: var(--star-color-dark);
        --wrapper: var(--wrapper-dark);
    }
}
