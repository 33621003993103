
.game-page-wrapper {
    background-color: var(--wrapper);
    min-height: 100vh;
}

.game-content-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 20px;
    max-width: 90%;
    margin: 0 auto;
    row-gap: 20px;
    /* margin-top: 2%; */
    /* border: 1px solid rgb(0, 4, 255); */
}

.left-content {
    flex-basis: 100%;
    flex-direction: row;
    display: flex;
    gap: 5px;
    overflow: auto;
    /* border: 1px solid rgb(255, 0, 247); */
    margin-top: 0;
    flex-direction: row;
    height: 100%;
    padding: 2%;
    justify-content: space-between;
    align-items: flex-start;
}

.right-content {
    flex-basis: 100%;
    width: 100%;
    margin-left: 2%;
    /* border: 1px solid blue; */
}

