.register-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: var(--wrapper); /* Background color */
}

.image-container {
  width: 38%; /* Adjust the width as desired */
  height: 38%; /* Adjust the height as desired */
  /* You can also add other styles as needed */
}

.register-title {
  font-size: 24px;
  margin-bottom: 20px;
  color: var(--text-color); /* Text color */
}

.register-form {
  width: 100%;
  max-width: 350px;
  padding: 20px;
  background-color: var(--background); /* Form background color */
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Box shadow */
}

.form-input {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

label {
  margin-right: 10px;
}

.register-button {
  background-color: var(--rating-color); /* Button background color */
  border: none;
  margin-left: 70px;
  color: var(--text-color);
  padding: 15px;
  text-align: center;
  text-decoration: none;
  display: block;
  font-size: 18px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 50%; /* Added to make the button full width */
}

.register-button:hover {
  background-color: var(--rating-color);
}

p {
  margin-top: 40px;
  color: var(--secondary-text-color);
}

.link-to-login {
  color: var(--rating-color);
  text-decoration: none;
  transition: color 0.2s;
}

.link-to-login:hover {
  color: var(--rating-color);
}

/* Styles for the error message popup */
.popup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent background */
  z-index: 1000; /* Ensure it's above other content */
}

.popup {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.popup-message {
  font-size: 16px;
  margin-bottom: 20px;
}

.popup button {
  background-color: var(--rating-color);
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
}

.popup button:hover {
  background-color: var(--rating-color);
}
