@media (prefers-color-scheme: dark) {
  .FavoriteGames {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: 100px;
    border: 1px solid white;
    width: 450px;
    height: 150px;
    padding: 10px;
    gap: 10px;
    color: white;
    border-radius: 20px;
  }
  .FavoriteGenres {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: 100px;
    border: 1px solid white;
    width: 450px;
    height: 200px;
    padding: 10px;
    gap: 10px;
    color: white;
    border-radius: 20px;
  }
  
  .FavoriteGames > div,
  .FavoriteGenres > div {
    border: 1px solid white;
    width: 100px;
    /* height: 125px; */
    text-align: center;
    border-radius: 20px;
  }
  
}
@media (prefers-color-scheme: light) {
  .FavoriteGames,
  .FavoriteGenres {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: 100px;
    border: 1px solid black;
    width: 450px;
    /* height: 150px; */
    padding: 10px;
    gap: 10px;
    color: black;
    border-radius: 20px;
  }
  
  .FavoriteGames > div,
  .FavoriteGenres > div {
    border: 1px solid black;
    width: 100px;
    /* height: 125px; */
    text-align: center;
    border-radius: 20px;
  }
  
}
  

.button {
  background-color: gray; /* Maintain the desired background color */
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  margin: 4px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.follow-button {
  max-width: 100px;

}

.currentlyPlayingGame {
  background-color: var(--background);
  border: 1px solid var(--background);
  padding: 2% 4%;
  /* margin: 1% 2%; */
  margin-left: 2%;
  margin-right: 2%;
  border-radius: 20px;
  text-align: center;
  box-sizing: border-box;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  /* max-height: 565px; */ 
  /* border: 1px solid var(--text-color); */
}
.Bio {
  border-radius: 20px;
}
.menuButtons {
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.five-recent {
  margin-left: 50px;
}

.mh {
  max-height: 375px;
}
.currentlyPlayingAndFeaturedList {
  gap:20px;
}