.rr-container {
    width: 600px;
    padding: 12px;
    border-radius: 25px;
    display: grid;
    grid-template-columns: repeat(24, 1fr);
    background: var(--background);
    margin-top: 10px;
    margin-left: 50px;
}

p {
    margin-top: 0px !important;
}

.rr-cover {
    grid-column: 1 / span 6;
}

.rr-cover-rounded {
    border-radius: 15px;
}
.rr-text {
    grid-column: 8 / span 18;
}

.user-name-rating {
    font-size: 20px;
}

.r-note {
    font-size: 15.8px;
}

.rr-note-more {
    color: gray;
    margin-left: 0px;
}


.rr-timestamp {
    text-align: right;
    color: var(--secondary-text-color);
}