.review-page-wrapper {
    /* Add any global styles for the wrapper */
}

.review-page-layout {
    display: flex;
    flex-direction: row;
    padding-top: 2rem;
    padding-right: 2rem;
    padding-left: 2rem;
}

.left-column {
    flex: 0 0 20%;
    padding: 1rem;
    /* Additional styling */
}

.right-column {
    flex: 0 0 80%;
    padding: 1rem;
}

/* Left side container styles */
.left-container {
    flex: 1;
    /* padding: 20px; */
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
}

.left-container > * {
    width: 100%;
    box-sizing: border-box;
}

/* Right side container styles */
.review-content-container {
    flex: 3;
    padding: 20px;
    width: 100%;
}

.review-text {
    /* Styles for the review text */
    padding-top: 20px;
}

.spoiler {
    background-color: var(--rating-color);
    color: var(--rating-color);
    cursor: pointer;
}
.spoiler:hover {
    color: var(--text-color);
}


.toggle-spoilers {
    margin: 10px 0;
    display: flex;
    align-items: center;
    font-weight: bold;
    color: var(--text-color);
}

.toggle-spoilers label {
    cursor: pointer;
}

.toggle-spoilers input {
    margin-right: 5px;
}

.spoiler-effect {
    background-color: var(--rating-color);
    color: var(--rating-color);
    cursor: pointer;
}
.spoiler-effect:hover {
    color: var(--text-color);
}

.spoiler {
    background-color: var(--rating-color); 
    color: var(--rating-color);
    cursor: pointer;
}


.delete-review-btn {
    background-color: #ff4d4d; /* Red background */
    color: #ffffff; /* White text */
    border: none;
    width: 20%;
    margin-left: auto;
    border-radius: 5px; 
    padding: 10px 20px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
}

.delete-review-btn:hover {
    background-color: #ff1a1a; /* Darker red on hover */
}

.right-container {
    /* Ensure that right-container fills the flex space appropriately */
    flex: 3;
    display: flex;
    flex-direction: column;
}

.review-comment-section {
    /* margin-top: 2rem; */
    /* padding: 0 1rem; */
    width: 100%;
}

.comment-display-section {
    margin-top: 2rem;
    /* padding: 0 1rem; */
    width: 100%;
}
