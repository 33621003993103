.activity-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 6.5px; /* Adjust the gap between grid items as needed */
    margin-bottom: 50px;
}

.user-activity {
    background: var(--background);
    border-radius: 25px;
    padding: 12px;
}

.user-head {
    font-size: 20px;
    font-weight: bold;
    color: var(--text-color);
}