.review-text-field-container {
    padding: 1rem;
    background-color: var(--background);
}

.review-text-field {
    width: 100%;
    height: 35rem;
    padding: 0.5rem;
    /* border: 1px solid var(--secondary-text-color); */
    border-radius: 10px;
    background-color: var(--background);
    color: var(--text-color);
    resize: none;
    outline: none;
    transition: border-color 0.3s ease;
}

.review-text-field::placeholder {
    color: var(--secondary-text-color);
}

.review-text-field:focus {
    border-color: var(--rating-color);
}

.review-character-count {
    margin-top: 3rem;
    text-align: right;
    color: var(--secondary-text-color);
}

.error-text {
    color: red;
}


/* You can integrate this within your Tailwind config or use it directly */
.spoiler-effect {
    background-color: black;
    color: black;
}

.spoiler-effect:hover {
    background-color: transparent;
    color: inherit;
}

.ql-spoiler .ql-stroke {
    display: none; /* hide default SVG */
}

.ql-spoiler::before {
    content: ''; 
    display: inline-block;
    width: 18px; 
    height: 18px; 
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"></path></svg>');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    color: white;
}

.ql-spoiler:hover::before {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="rgb(0, 102, 255)" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"></path></svg>');
}

