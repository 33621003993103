.profile-game-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between; /* Ensure content is evenly distributed */
    text-align: center;
    /* border: 1px solid white; */
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    /* padding: 10px; */
    color: white;
    cursor: pointer;
  }
  
  .profile-game-card img {
    flex-grow: 1; /* Image takes up available space */
    width: 100%;
    max-height: 100%;
    object-fit: cover;
    border-radius: 0;
    border-radius: 20px;
  }
  
  .profile-game-card h2 {
    margin: 10px 0;
    font-size: 16px;
  }
  
  