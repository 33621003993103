.modal {
  font-family: Arial, sans-serif;
  text-align: center;
  color: white;
}

.modal form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal input {
  margin: 10px 0;
  padding: 8px;
  width: 80%;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: #333;
  color: white;
}

.modal input[type="file"] {
  margin: 10px 0;
  width: 80%;
}

.modal button {
  margin: 10px;
  padding: 10px 20px;
  font-size: 16px;
  /* border: 2px solid #007bff; */
  border-radius: 5px;
  cursor: pointer;
}

.modal label {
  font-size: 16px;
  margin-bottom: 6px;
  align-self: flex-start;
  width: 80%;
  text-align: center;
  margin-right: 100px;
}

.modal button.button {
  background-color: #007bff;
  color: white;
}

.modal button.button:hover {
  background-color: #0056b3;
}

.modal button.button:focus {
  outline: none;
}

.modal button.button:active {
  background-color: #004080;
}

.modal button.button.secondary {
  background-color: #6c757d;
  border-color: #6c757d;
}

.modal button.button.secondary:hover {
  background-color: #5a6268;
}

.modal button.button.secondary:focus {
  outline: none;
}

.modal button.button.secondary:active {
  background-color: #495057;
}

/* Additional styling for buttons inside the modal */
.modal button[type="submit"] {
  background-color: #007bff;
  color: white;
  border: none;
}

.modal button[type="submit"]:hover {
  background-color: #0056b3;
}

/* Style the "Close" button */
.modal button[type="close"] {
  background-color: #007bff;
  color: white;
  border: none;
}

.modal button[type="close"]:hover {
  background-color: #5a6268;
}

/* Style the "Edit Profile" button */
.button {
  background-color: #34333358; /* Use a lighter grey background color */
  color: rgb(255, 255, 255); /* Change text color to black */
  border: 2px solid #34333358; /* Use a lighter grey border color */
  height: 50px;
}

.deleteButton {
  background-color: #dc3545; /* Use a red background color */
  color: white; /* Change text color to white */
  border: none; /* Remove border */
  border-radius: 5px; /* Add rounded corners */
  padding: 10px 20px; /* Add padding */
  font-size: 16px; /* Increase font size */
  cursor: pointer; /* Add cursor on hover */
}

.deleteButton:hover {
  background-color: #c82333; /* Use a slightly darker red on hover */
}

.deleteButton:focus {
  outline: none; /* Remove focus outline */
}

.deleteButton:active {
  background-color: #bd2130; /* Use an even darker red when active */
}


.button:hover {
  background-color: #bbb; /* Use a slightly darker grey on hover */
}

.button:focus {
  outline: none;
}

.button:active {
  background-color: #aaa; /* Use an even darker grey when active */
}

.delete-account-modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete-account-modal-content {
  background-color: #333;
  color: white;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 500px;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.delete-account-modal-content input {
  margin: 10px 0;
  padding: 8px;
  width: 80%;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: #555;
  color: white;
}

.delete-account-modal-content button {
  margin: 10px;
  padding: 10px 20px;
  font-size: 16px;
  border: 2px solid #007bff;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.delete-account-modal-content button:hover {
  background-color: #0056b3;
}
