.resultsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.gamesColumn, .usersColumn {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin-top: 16px;
}
.gamesColumn {
    margin-left: 100px;
}

.usersColumn {
  margin-right: 50px;
  max-height: 250px;
}

.headingsGameUser {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto; /* Center the headings */
  font-size: 2rem;
  gap: 600px;
}
.searchPageTitle {
    text-align: center; /* Center the text */
    margin-top: 10px; /* Add some top margin for spacing */
  }
  .filterContainer {
    display: flex;
    justify-content: center;
    margin-top: 16px;
  }
  
  .filterSelect {
    margin-right: 16px;
  }
  .genreContainer, .platformContainer {
    justify-content: center;
    text-align: center;
  }
  .genreLabel, .platformLabel {
    color: var(--text-color);
  }
