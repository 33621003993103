.list-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--background);
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px; /* Adjust the width as needed */
  max-width: 80%; /* Limit the width to 80% of the viewport */
  max-height: 80vh; /* Limit the height to 80% of the viewport height */
  overflow-y: auto; /* Add scrollbar if content overflows */
  text-align: center; /* Center the content horizontally */

  /* Center the content vertically using flexbox */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.list-popup button {
  margin: 5px;
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.list-popup button:hover {
  background-color: #0056b3;
}

.list-popup input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  color: black;
}

.add-to-list-button {
  /* Add your custom styles here */
  margin-top: 10px; /* Example margin */
}
