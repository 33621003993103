.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Set height to 100% of the viewport height */
  background-color: var(--wrapper); /* Background color */
  color: var(--secondary-text-color);
}
.mainlogin-image-container {
  width: 65%; /* Adjust the width as desired */
  height: 65%;
}

.login-page-title {
  font-size: 24px;
  margin-bottom: 20px;
  color: var(--text-color); /* Text color */
}

.form-container {
  width: 100%;
  max-width: 350px;
  padding: 20px;
  background-color: var(--background); /* Form background color */
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Box shadow */
}

.form-input {
  margin-bottom: 20px;
}

label {
  display: block;
  font-size: 16px;
  color: var(--secondary-text-color); /* Label color */
}

input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc; /* Input border color */
  border-radius: 5px;
  font-size: 16px;
}

.login-button {
  background-color: var(--rating-color); /* Button background color */
  border: none;
  margin-left: 70px;
  color: var(--text-color);
  padding: 15px;
  text-align: center;
  text-decoration: none;
  display: block;
  font-size: 18px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 50%; /* Added to make the button full width */
}

.login-button:hover {
  background-color: var(--rating-color); /* Hover color */
}

.link-to-register {
  color: var(--rating-color); /* Link color */
  text-decoration: none;
  transition: color 0.2s;
}

.link-to-register:hover {
  color: var(--rating-color); /* Hover color */
}

.error-message {
  color: #ff6347; /* Error message color */
  font-size: 16px;
  margin-top: 10px;
}
